import axios from "axios";
import parseUserData from '../parsers/UserDataParsers'

const userLogin = async (baseUrl, key, email, password) => {

    const basicAuth = btoa(email + ":" + password)

    console.log(basicAuth)
    
    let userResponse = null

    await axios.put(baseUrl + `/api/v1/user/` + key, {}, { headers: { 'Authorization': "Basic " + basicAuth} } )
            .then((res) => {
                let serviceResult = res.data

                let user = parseUserData(serviceResult)

                userResponse= {
                    status: res.status,
                    data: user
                }
            }).catch((error) => {
                
                userResponse= {
                    status: error.response.status,
                    data: error.response.data.errors[0]
                }
            });
    return userResponse
}

export default userLogin;