import axios from "axios";

const dataList = async (baseUrl, token, key, disability, nameSurname, neighbourhood) => { 
 
    let dataListResponse = null

    await axios.get(baseUrl + `/api/v1/data/` + key + "?" + "access_token=" + token + "&disability=" + disability +"&name=" + nameSurname +
                    "&neighborhood=" + neighbourhood)
            .then((res) => {
                let serviceResult = res.data
                dataListResponse= {
                    status: res.status,
                    data: serviceResult.result
                }
            }).catch((error) => {
                
                dataListResponse= {
                    status: error.response.status,
                    data: error.response.data.errors[0]
                }
            });
    return dataListResponse
}

export default dataList;
