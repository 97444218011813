import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider } from "mobx-react";
import { onSnapshot } from 'mobx-state-tree'

import { KentRehberiStore } from './store/KentRehberiStore'

let layer = [
  {
    id: 1,
    name: "Sokak",
    provider: "Mapbox",
    image: "/assets/images/layer/mapbox-street.png",
    url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}@2x?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg"
  },
  {
    id: 2,
    name: "Koyu",
    provider: "Mapbox",
    image: "/assets/images/layer/mapbox-dark.png",
    url: "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg"
  },
  {
    id: 3,
    name: "Uydu",
    provider: "Mapbox",
    image: "/assets/images/layer/mapbox-satellite.png",
    url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg"
  },
  {
    id: 4,
    name: "Uydu",
    provider: "Esri",
    image: "/assets/images/layer/esri-satellite.png",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    access_token: ""
  },
  {
    id: 5,
    name: "Topografik",
    provider: "Esri",
    image: "/assets/images/layer/esri-topografic.png",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    access_token: ""
  },
  {
    id: 6,
    name: "Sokak",
    provider: "Open Street Map",
    image: "/assets/images/layer/openstreetmap-sokak.png",
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    access_token: ""
  },
  {
    id: 7,
    name: "Bisiklet Yolu",
    provider: "Thunderforest",
    image: "/assets/images/layer/thunderforest-cycle.png",
    url: "https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=",
    access_token: "e83bb847b1574d589a86bdd341da9249"
  },
  {
    id: 8,
    name: "Gri",
    provider: "Stadia Maps",
    image: "/assets/images/layer/stadiamaps-gray.png",
    url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    access_token: "",
   }
]

let initialState = {
  baseUrl: "https://ujm38dbwdj.execute-api.us-east-1.amazonaws.com",
  name: "Foça Belediyesi",
  logoUrl: "/assets/images/focalogo.jpg",
  mapCenterLat: 38.6792,
  mapCenterLng: 26.8501,
  mapZoomLevel: 12,
  listPanel: false,
  layerPanel: false,
  lejantPanel: false,
  isLogin: false,
  token: "",
  layer: layer
}

if (localStorage.getItem("mapisso.kentrehberi.v1")) {
  // console.log("localstorage if")
  //localStorage.clear("mapisso.covid19")
  initialState = JSON.parse(localStorage.getItem("mapisso.kentrehberi.v1"))
}

let mapissoViewerStore = KentRehberiStore.create(initialState)

onSnapshot(mapissoViewerStore, snapshot => {
  localStorage.setItem("mapisso.kentrehberi.v1", JSON.stringify(snapshot))
})

function renderApp() {
  ReactDOM.render(
      <Provider store={mapissoViewerStore}>
          <App />
      </Provider>, document.getElementById('root'));
}

renderApp()


