import React, { useCallback, useState, useEffect } from 'react'

import L from 'leaflet';

import IconButton from '@material-ui/core/IconButton';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

function MpsZoomToExtent (props) {

    const onClick = useCallback(() => {

       props.map.setView(new L.LatLng(props.center[0], props.center[1]), props.center[2]);

    }, [props.map])

    return (

        <IconButton aria-label="zoom-extent" className="mapisso-zoom-to-extent" onClick={onClick}>
            <ZoomOutMapIcon />
        </IconButton>

    )
}

export default MpsZoomToExtent;