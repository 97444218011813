import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import dataList from '../utils/servicerequests/DataListGet';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InputBase from '@material-ui/core/InputBase';
import Avatar from '@material-ui/core/Avatar'; import Tooltip from '@material-ui/core/Tooltip';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import LayersIcon from '@material-ui/icons/Layers';

import getGeocode from '../utils/servicerequests/GeocodeGet'

import { MapissoMapContainer } from './MapissoMapContainer';
import { MpsLejantButton } from '../components/MapElements/MpsLejantButton';
import getColorCode from "../components/MapElements/MpsGetColor";

const disabilities = ["Ağır Engelli", "Ağır Mental", "Alzeimer", "Bedensel Engelli", "Bipolar Bozukluk", "Böbrek Yetmezliği", "Boğazı Delik",
  "Cam Kemik", "Diğer", "Down Sendromu", "Epilepsi", "Eve Bağımlı", "Felç", "Görme Engelli", "Hiperlipidemi", "İşitme Engelli",
  "Kalp Hastalığı", "Koah", "Konuşma Engelli", "Ortopedik", "Otistik", "Parkinson", "Romatizma", "Serabral", "Vertigo", "Yaşlılık",
  "Yatalak", "Yürüme Engelli", "Zihinsel Engelli"];

class MapPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loadingBarStatus: true,
      dataList: null,
      engelliArray: [],
      mapCenterArray: [this.props.store.mapCenterLat, this.props.store.mapCenterLng, this.props.store.mapZoomLevel],
      selectedEngelli: null,
      nameSurname: "",
      geocoderValue: "",
      display: false,
      clientName: "",
      selectedBaseLayer: this.props.store.layer[0],
      geocoderResultDisplay: false,
      geocoderResult: [],
      selectedGecoderResult: null,
      mapOperationName: ""
    }
  }

  componentDidMount = async () => {
    console.log("props: ", this.props.location.search)

    if (this.props.store.isLogin) {

      this.setState({
        loadingBarStatus: false,
      })

      let clientName = ""
      if (this.props.location.search !== undefined) {
        clientName = this.props.location.search
        clientName = clientName.split("=")[1]
        let dataListResponse = await dataList(this.props.store.baseUrl, this.props.store.token, clientName, "", "", "")
        console.log("dataListResponse: ", dataListResponse)

        if(dataListResponse.status === 401){
          this.props.store.setUserLogout()
          this.props.history.push("/login");
        }else{
          this.setState({
            dataList: dataListResponse.data,
            loadingBarStatus: true,
            clientName: clientName
          })
        }
        
      }else{
        this.setState({
          loadingBarStatus: true
        })
      }

    } else {
      this.props.history.push("/login");
    }
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.startingLetter;
    const bandB = b.startingLetter;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  zoomToEngelliListItem = (item) => {
    let coordinateArray = item.data.geometry.coordinates[0]
    let totalLat = 0
    let totalLng = 0

    var i;
    for (i = 0; i < coordinateArray.length; i++) {
      totalLat += coordinateArray[i][1]
      totalLng += coordinateArray[i][0]
    }

    let centerLat = totalLat / coordinateArray.length
    let centerLng = totalLng / coordinateArray.length

    this.setState({
      mapCenterArray: [centerLat, centerLng, 18]
    })

    this.setState({
      selectedEngelli: item.data,
      mapOperationName: "itemselection"
    })
    //this.props.store.setMapCenter([centerLat, centerLng, 16])
  }

  onChangeNameFilter = async (event) => {
    this.setState({
      nameSurname: event.target.value
    })
  }

  onClickedQuery = async () => {
    let dataListResponse = await dataList(this.props.store.baseUrl, this.props.store.token, this.state.clientName, "", this.state.nameSurname, "")
    this.setState({
      dataList: dataListResponse.data
    })
  }

  onDeleteQuery = async () => {
    if (this.state.nameSurname !== "") {
      let dataListResponse = await dataList(this.props.store.baseUrl, this.props.store.token, this.state.clientName, "", "", "")
      this.setState({
        dataList: dataListResponse.data,
        nameSurname: ""
      })
    }
  }

  onToggleLayer = (layer) => {
    this.setState({
      selectedBaseLayer: layer
    })
  }

  onToggleMenu = async () => {
    this.setState({
      display: !this.state.display
    })
  }

  onLogout = () => {
    this.props.store.setUserLogout()
    window.location.reload(false);
  }

  onListPanelShow = () => {
    this.props.store.setListPanel(!this.props.store.listPanel)
    this.props.store.setLayerPanel(false)
  }

  onLayerPanelShow = () => {
    this.props.store.setListPanel(false)
    this.props.store.setLayerPanel(!this.props.store.layerPanel)
  }

  onGeocoderTextChange = (event) => {
    this.setState({
      geocoderValue: event.target.value
    })
  }

  onGeocodeReqClick = async () => {
    this.setState({
      loadingBarStatus: false
    })
    let geocodeResult = await getGeocode(this.props.store.baseUrl, this.props.store.token, this.state.clientName, encodeURI(this.state.geocoderValue))
    if (geocodeResult.data != null) {
      if (typeof geocodeResult.data.features !== "undefined") {
        this.setState({
          geocoderResultDisplay: true,
          geocoderResult: geocodeResult.data.features

        })
      }
    }
    this.setState({
      loadingBarStatus: true
    })
  }

  onSelectedQueryResult = (selectedResult) => {
    this.setState({
      selectedGecoderResult: selectedResult,
      geocoderResultDisplay: false,
      mapOperationName: "geocoder"
    })
  }

  render() {
    let engelliArray = []

    if (this.state.dataList != null) {

      this.state.dataList.features.map((item, idx) => (
        engelliArray.push({
          startingLetter: item.properties.adisoyadi.slice(0, 1),
          data: item
        })
      ))
      engelliArray.sort(this.compare);
    }

    return (
      <div>
        <LinearProgress hidden={this.state.loadingBarStatus} className="mps-looading-bar" />
        <img src="assets/images/mapisso-logo.png" className="mapisso-logo"></img>

        <Paper className="mapisso-search-box">
          <InputBase
            className="mapisso-search-input"
            placeholder="Adres ara"
            value={this.state.geocoderValue}
            onChange={this.onGeocoderTextChange}
            inputProps={{ 'aria-label': 'Ara' }}
          /> 
          <IconButton type="submit" aria-label="search" onClick={this.onGeocodeReqClick} >
            <SearchIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem style={{ backgroundColor: "#efefef" }} />
          <IconButton color="primary" aria-label="engellilist" onClick={this.onListPanelShow}>
            <PersonIcon />
          </IconButton>
          <IconButton color="primary" aria-label="layers" onClick={this.onLayerPanelShow}>
            <LayersIcon />
          </IconButton>
        </Paper>

        <Paper className="mapisso-geocoder-resultbox" style={{ display: (this.state.geocoderResultDisplay) ? "block" : "none" }}>
          <List>

            {(this.state.geocoderResult.length > 0) ? this.state.geocoderResult.map((item, idx) => (
              <ListItem key={idx} className="geocoder-result-item" onClick={() => this.onSelectedQueryResult(item)}>
                <ListItemText primary={item.place_name} />
              </ListItem>
            )) : ""}
          </List>
        </Paper>
        <MpsLejantButton />

        <div id="profile" className="mapisso-profile-container">
          <Avatar className="mapisso-profile-avatar" onClick={() => this.onToggleMenu()}>
            <img src="assets/images/focalogo.jpg" style={{ width: "50px" }}></img>
          </Avatar>
        </div>
        <div className="mapisso-dropdown-menu" style={{ display: (this.state.display) ? 'block' : "none" }}>
          <Grid container spacing={0} >
            <Grid item xs={12} style={{ padding: "13px", paddingTop: "20px", borderBottom: "1px solid rgb(62 6 162)" }}>
              <h4 style={{ color: "#fff", marginBottom: "0" }}>{(this.props.store.user.length > 0) ? this.props.store.user[0].name : ""}</h4>
              <p style={{ color: "#fff" }}>{(this.props.store.user.length > 0 ) ? this.props.store.user[0].surname : ""}</p>
            </Grid>
            <Grid item xs={12} style={{ padding: "13px", paddingBottom: "0px" }}>
              <Link href="#" className={"nav-link"} onClick={this.onLogout}>
                <span className="dropdown-menu-span">Çıkış Yap</span>
              </Link>
            </Grid>
          </Grid>
        </div>

        <div className="mapisso-list-panel" style={{ display: (this.props.store.listPanel) ? "block" : "none" }}>
          <h5 style={{ fontWeight: "bold" }}>Engelli Vatandaş Listesi</h5>

          <Paper style={{ background: "transparent", border: "0", boxShadow: "none" }}>
            <InputBase
              className="mapisso-search-input"
              placeholder="Kişi ara"
              value={this.state.nameSurname}
              inputProps={{ 'aria-label': 'Ara' }}
              onChange={this.onChangeNameFilter}
              style={{ width: "250px", background: "#d4d2d2", height: "50px", padding: "10px", borderRadius: "5px" }}
            />
            <Tooltip title="Kişi Ara">
              <IconButton type="submit" aria-label="search" onClick={this.onClickedQuery}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Temizle">
              <IconButton type="submit" aria-label="search" onClick={this.onDeleteQuery}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Paper>

          <div className="overflow-auto engelli-envanter-container">
            <List>
              {(this.state.dataList != null) ?
                engelliArray.map((item, idx) => (
                  <ListItem key={idx} className="engelli-envanter-listitem" onClick={() => this.zoomToEngelliListItem(item)}>
                    <ListItemAvatar>
                      <Avatar>
                        {
                          item.data.properties.adisoyadi.slice(0, 1)
                        }
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={(item.data.properties.adisoyadi.split(" ")[0] !== undefined) ? item.data.properties.adisoyadi.split(" ")[0].charAt(0) + "***** ***** ": "***** *****"} secondary={item.data.properties.telefonno} />
                    <ListItemSecondaryAction>
                      <b style={{ fontSize: "12px" }}>{item.data.properties.engeldurum}</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                )) : ""
              }
            </List>
          </div>
        </div>
        <div className="mapisso-list-panel" style={{ display: (this.props.store.layerPanel) ? "block" : "none" }}>
          <h5 style={{ fontWeight: "bold" }}>Katman Listesi</h5>
          <div className="overflow-auto mapisso-layer-container">
            {this.props.store.layer.map((item, idx) => (
              <Card key={idx} className="layer-card">
                <div style={{ display: 'flex', flexDirection: 'column', width: "calc(100% - 151px)" }}>
                  <CardContent style={{ flex: '1 0 auto', paddingBottom: "0" }}>
                    <Typography component="h5" variant="h5">
                      {item.name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {item.provider}
                    </Typography>
                  </CardContent>
                  <div >
                    <IconButton aria-label="play/pause" onClick={() => this.onToggleLayer(item)}>
                      {(this.state.selectedBaseLayer.id === item.id) ? <DoneIcon /> : <AddIcon />}
                    </IconButton>
                  </div>
                </div>
                <CardMedia
                  image={item.image}
                  title="Live from space album cover"
                  style={{ width: "151px" }}
                />
              </Card>
            ))
            }
          </div>
        </div>
        <div className="mapisso-legend" style={{ display: (this.props.store.lejantPanel) ? "block" : "none" }}>
          <h5 style={{ fontWeight: "bold" }}>Lejant</h5>
          {disabilities.map((item, idx) => (
            <Chip key={idx} label={item} style={{ background: getColorCode(item), color: '#fff', margin: "5px", fontWeight: "bold" }} />
          ))
          }
        </div>

        <MapissoMapContainer mapData={this.state.dataList} center={this.state.mapCenterArray}
          selected={this.state.selectedEngelli} baseLayer={this.state.selectedBaseLayer}
          selectedGecoder={this.state.selectedGecoderResult} operationName={this.state.mapOperationName} />
      </div >

    )
  }
}

export default inject("store")(observer(MapPage));
