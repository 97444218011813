import React, { useState, useCallback, useEffect } from 'react'

function MpsCoordinates({ map }) {
	const [position, setPosition] = useState(null)
	useEffect(() => {
		if(map != null){
			map.on('mousemove',  (e) => {
			  setPosition(e.latlng)
			})
			return () => {
			  map.off('mousemove', (e) => {
				setPosition(e.latlng)
			  })
			}
		}
      }, [map])

	return (
	  <div className="mapisso-coordinate-container">
		<p>
		   {(position != null) ? "Enlem: " + position.lat.toFixed(4) + ", " +  "Boylam: " + position.lng.toFixed(4) : ""}
		</p>
	  </div>
	)
  }

  export default MpsCoordinates;