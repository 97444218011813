import { types } from "mobx-state-tree"

import {User} from '../model/User'
import {Layer} from '../model/Layer'

export const KentRehberiStore = types
.model("KentRehberiStore", {
    baseUrl: types.string,
    name: types.string,
    logoUrl: types.string,
    mapCenterLat: types.number,
    mapCenterLng: types.number,
    mapZoomLevel: types.number,
    listPanel: types.boolean,
    layerPanel: types.boolean,
    lejantPanel: types.boolean,
    isLogin: types.boolean,
    user: types.optional(types.array(User), []),
    layer: types.optional(types.array(Layer), []),
    token: types.string
}).actions(self => ({
    setMapCenter(array) {
        self.mapCenterLat = array[0]
        self.mapCenterLng = array[1]
        self.mapZoomLevel = array[2]
    },
    setName(name){
        self.name = name
    },
    setLogoUrl(url){
        self.logoUrl = url
    },
    setBaseUrl(baseUrl){
        self.baseUrl = baseUrl
    },
    setListPanel(display){
        self.listPanel = display
    },
    setLayerPanel(display){
        self.layerPanel = display
    },
    setLejantPanel(display){
        self.lejantPanel = display
    },
    setUserLogged() {
        self.isLogin = true
    },
    setUserLogout() {
        self.isLogin = false
        self.token = ""
        self.user = []
    },
    setToken: (function (token) {
        self.token = token
    }),
    setUser: (function (user) {
        self.user = []
        self.user.push(user)
    }),
    setLayer: (function (layer){
        self.layer = []
        self.layer.push(layer)
    })
}))
