import React, { useCallback } from 'react'
import { observer, inject } from "mobx-react"

import IconButton from '@material-ui/core/IconButton';
import TextureIcon from '@material-ui/icons/Texture';

export const MpsLejantButton = inject("store")(observer((props) => {
    
    const onLejantButtonClick = useCallback(() => {
        props.store.setLejantPanel(!props.store.lejantPanel)
      }, [])
    
   
    return (
        <div className="mapisso-lejant-button-container">
            <IconButton aria-label="lejant" color="primary" onClick={onLejantButtonClick}>
                <TextureIcon />
            </IconButton>           
        </div>
    )
}))
