import React from 'react';
import { observer, inject } from "mobx-react"
import L from 'leaflet';

import MpsCoordinates from '../components/MapElements/MpsCoordinates'
import MpsZoomBox from '../components/MapElements/MpsZoomBox'
import MpsFindMyLocation from '../components/MapElements/MpsFindMyLocation'
import MpsZoomToExtent from '../components/MapElements/MpsZoomToExtent';

import getColorCode from "../components/MapElements/MpsGetColor";
import mahalleGeojson from "./mahalle.json"

const selectedEngelliStyle = {
  "color": "#FFFF00",
  "weight": 5,
  "opacity": 1
};

const mahalleStyle = {
  "color": "#4D0070",
  "strokeWidth": 1,
  "fillOpacity": 0
};

const mapissoIcon = L.icon({
  iconUrl: '/assets/images/mappin.png',
  shadowUrl: '',
  iconSize: [32, 32], // size of the icon
  iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
});

function onEachFeature(feature, layer) {
  // does this feature have a property named popupContent?
  if (feature.properties && feature.properties.adisoyadi) {

    let popupValue = '<div class="MuiChip-root" style="background:' + getColorCode(feature.properties.engeldurum) + '; color: rgb(255, 255, 255); margin: 5px; font-weight: bold;"><span class="MuiChip-label">' + feature.properties.engeldurum + '</span></div>'
    popupValue += '<p style="margin: 0px"><b>Adı Soyadı: </b>' + feature.properties.adisoyadi + '</p>'
    popupValue += '<p style="margin: 0px"><b>Telefon No: </b>' + feature.properties.telefonno + '</p>'
    layer.bindPopup(
      popupValue
    )
  }
}

function style(feature) {
  return {
    fillColor: getColorCode(feature.properties.engeldurum),
    weight: 2,
    opacity: 1,
    color: getColorCode(feature.properties.engeldurum),
    fillOpacity: 0.7
  };
}

export const MapissoMapContainer = inject("store")(observer((props) => {

  const mapRef = React.useRef(null);
  console.log( "mapRef: ", mapRef)
  React.useEffect(() => {
    // create map
    let layer = null

    if (props.baseLayer != null && props.baseLayer != "") {

      let url = props.baseLayer.url
      if (props.baseLayer.access_token != "") {
        url = url + "?access_token=" + props.baseLayer.access_token
      }
      layer = L.tileLayer(url);
    }

    mapRef.current = L.map('map', {
      zoomControl: false,
      attributionControl: false,
      center: [props.store.mapCenterLat, props.store.mapCenterLng],
      zoom: props.store.mapZoomLevel,
      layers: [
        layer
      ]
    });

    L.geoJSON(mahalleGeojson, {
      style: mahalleStyle
    }).addTo(mapRef.current);

  }, []);

  console.log( "mapRef: ", mapRef)

  const geojsonRef = React.useRef(null);

  React.useEffect(
    () => {
      if (geojsonRef.current) {
        mapRef.current.removeLayer(geojsonRef.current);
        L.geoJSON(props.mapData, { style: style, onEachFeature: onEachFeature }).addTo(mapRef.current);
      } else {
        L.geoJSON(props.mapData, { style: style, onEachFeature: onEachFeature }).addTo(mapRef.current);
      }
    },
    [props.mapData]
  );

  React.useEffect(() => {

    if (props.operationName === "itemselection") {
      if (props.selected != null) {
          mapRef.current.eachLayer(function (layer) {
          if (layer.options.style != undefined) {
            if (layer.options.style.color === "#FFFF00") {
              mapRef.current.removeLayer(layer)
            }
          }
        });

        L.geoJSON(props.selected, {
          style: selectedEngelliStyle
        }).addTo(mapRef.current);

        let coordinates = props.selected.geometry.coordinates[0][0]
        mapRef.current.setView([coordinates[1], coordinates[0]], 19);
      }
    }

    if(props.operationName === "geocoder"){
      if (props.selectedGecoder != null) {
        mapRef.current.eachLayer(function (layer) {
          if (layer instanceof L.Marker) {
            mapRef.current.removeLayer(layer);
          }
        })
        L.marker([props.selectedGecoder.geometry.coordinates[1], props.selectedGecoder.geometry.coordinates[0]], { icon: mapissoIcon }).addTo(mapRef.current).bindPopup(props.selectedGecoder.place_name);
        mapRef.current.setView([props.selectedGecoder.geometry.coordinates[1], props.selectedGecoder.geometry.coordinates[0]], 19);
      }
    }

    let layer = null
    if (props.baseLayer != null && props.baseLayer != "") {
      let url = props.baseLayer.url
      if (props.baseLayer.access_token != "") {
        url = url + props.baseLayer.access_token
      }
      layer = L.tileLayer(url);
      mapRef.current.eachLayer(function (layer) {
        if (layer instanceof L.TileLayer) {
          mapRef.current.removeLayer(layer);
        }
      })
      layer.addTo(mapRef.current);
    }
  }, [props]);

  return (
    <div id="map" style={{ width: "100%", height: "100vh" }}>
      <MpsCoordinates map={mapRef.current} />
      <MpsZoomBox map={mapRef.current} />
      <MpsFindMyLocation map={mapRef.current} />
      <MpsZoomToExtent map={mapRef.current} center={[props.store.mapCenterLat, props.store.mapCenterLng, props.store.mapZoomLevel]} />
    </div>
  )
}))
