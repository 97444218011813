import { types } from "mobx-state-tree"

export const User = types
    .model({
        id: types.optional(types.string, ""),
        name: types.optional(types.string,""),
        surname: types.optional(types.string, ""),       
        token: types.optional(types.string, ""),
        licenceKey: types.optional(types.string, "")
    }).actions(self => ({

    }))