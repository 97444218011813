import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { observer, inject } from "mobx-react"

import MapPage from './pages/MapPage'
import LoginPage from './pages/LoginPage'

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/harita" component={MapPage} />
        </Switch>
      </Router>
    )
  }
}

export default inject("store")(observer(App));

