const getColorCode = function (d) {
    return d === "Ağır Engelli" ? '#A93226' :
        d === "Ağır Mental" ? '#CB4335' :
            d === "Alzeimer" ? '#884EA0' :
                d === "Bedensel Engelli" ? '#7D3C98' :
                    d === "Bipolar Bozukluk" ? '#2471A3' :
                        d === "Böbrek Yetmezliği" ? '#2E86C1' :
                            d === "Boğazı Delik" ? '#17A589' :
                                d === "Cam Kemik" ? '#138D75' :
                                    d === "Diğer" ? "#229954" :
                                        d === "Down Sendromu" ? "#28B463" :
                                            d === "Epilepsi" ? "#D4AC0D" :
                                                d === "Eve Bağımlı" ? "#D68910" :
                                                    d === "Felç" ? "#CA6F1E" :
                                                        d === "Görme Engelli" ? "#BA4A00" :
                                                            d === "Hiperlipidemi" ? "#D0D3D4" :
                                                                d === "İşitme Engelli" ? "#A6ACAF" :
                                                                    d === "Kalp Hastalığı" ? "#839192" :
                                                                        d === "Koah" ? "#2E4053" :
                                                                            d === "Konuşma Engelli" ? "#273746" :
                                                                                d === "Ortopedik" ? "#52BE80" :
                                                                                    d === "Otistik" ? "#F4D03F" :
                                                                                        d === "Parkinson" ? "#5B2C6F" :
                                                                                            d === "Romatizma" ? "#C0392B" :
                                                                                                d === "Serabral" ? "#17202A" :
                                                                                                    d === "Vertigo" ? "#48C9B0" :
                                                                                                        d === "Yaşlılık" ? "#5B2C6F" :
                                                                                                            d === "Yatalak" ? "#873600" :
                                                                                                                d === "Yürüme Engelli" ? "#0B5345" :
                                                                                                                    d === "Zihinsel Engelli" ? "#1B4F72" :
                                                                                                                        '#F9EBEA';

}

export default getColorCode;
