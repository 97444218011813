import React, { useCallback, useState, useEffect } from 'react'

import IconButton from '@material-ui/core/IconButton';
import MyLocationIcon from '@material-ui/icons/MyLocation';

function MpsFindMyLocation({ map }) {
    const onClick = useCallback(() => {
        map.locate({ setView: true, watch: true }) /* This will return map so you can do chaining */
            .on('locationfound', function (e) {
            })
            .on('locationerror', function (e) {
                alert("Konum erişimi reddedildi.");
            });
    }, [map])

    return (

        <IconButton aria-label="zoom-in" className="mapisso-find-my-location-button" onClick={onClick}>
            <MyLocationIcon />
        </IconButton>

    )
}

export default MpsFindMyLocation;