import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import userLogin from '../utils/servicerequests/UserLogin'

class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingBarStatus: true,
            email: "",
            password: ""
        }
    }

    componentDidMount = async () => {
        if (this.props.store.isLogin) {
            this.props.history.push("/harita");
        } else {
            this.props.history.push("/login");
        }
    }

    onLogin = async () => {
        this.setState({
            loadingBarStatus: false
        })

        let response = await userLogin(this.props.store.baseUrl, "foca", this.state.email, this.state.password)

        if (response.status === 200) {
            let user = response.data
            this.props.store.setUser(user)
            this.props.store.setToken(user.token)
            this.props.store.setUserLogged()
            this.setState({
                loadingBarStatus: true
            })
            this.props.history.push("/harita?client="+ user.licenceKey);
        } else {
            this.props.store.setToken("")
            this.setState({
                loadingBarStatus: true
            })
        }
    }

    onEmailTextFieldChange = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    onPasswordTextFieldChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    render() {
        return (
            <div className="container reazyContainer">
                <LinearProgress hidden={this.state.loadingBarStatus} color="secondary" className="mps-looading-bar"/>
                <div className="row width100vh">
                    <div className="col-md-12 height100vh">
                        <div className="d-flex align-items-center justify-content-center" style={{height: "100vh"}}>
                            <div className="p-2 bd-highlight col-example" style={{textAlign: "center"}}>
                                <img src="assets/images/mapisso-logo.png" style={{width: "250px", marginBottom: "30px"}}></img>
                                <p className="h3" style={{fontWeight: "bold", marginBottom: "50px"}}>Artık Seni Duyuyorum! <br/> <span style={{fontWeight: "100"}}>platformuna hoşgeldiniz.</span></p>
                                <p style={{fontWeight: "bold"}}>E-posta</p>
                                <TextField id="outlined-basic" variant="outlined" style={{ width: "350px" }} onChange={this.onEmailTextFieldChange} />
                                <p style={{fontWeight: "bold", marginTop: "25px"}}>Şifre</p>
                                <TextField id="3dtiles-input4" variant="outlined"  type="password" style={{ width: "350px", marginBottom: "20px" }} onChange={this.onPasswordTextFieldChange} />
                                <p></p>
                                <Button variant="contained" color="secondary" onClick={this.onLogin}
                                            style={{ width: "150px", height: "50px", fontWeight: "600" }}>
                                            Giriş Yap
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default inject("store")(observer(LoginPage));
