/**
 * This method transform Login API response doc to User
 * @param {*} userResponse 
 */
const parseUserData = (userResponse) => {

    let tokenValue = ""
    if(userResponse.result.token !== undefined){
        tokenValue = userResponse.result.token
    }

    let user = {
        id: userResponse.result.id,
        name: userResponse.result.name,
        surname: userResponse.result.surname,
        token: tokenValue,
        licenceKey: userResponse.result.licenceKey
    }

    return user;
}

export default parseUserData;