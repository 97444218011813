import React, { useState, useEffect, useCallback } from 'react'

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function MpsZoomBox({ map }) {

    console.log("map: ", map)
    
    const onZoomInClick = useCallback(() => {
        console.log("map2: ", map)
        map.setZoom(map.getZoom() + 1)
      }, [map])
    
    const onZoomOutClick = useCallback(() => {
        map.setZoom(map.getZoom() - 1)
      }, [map])

    return (
        <div className="mapisso-zoombox-container">
            <IconButton aria-label="zoom-in" className="mapisso-zoom-button" onClick={onZoomInClick}>
                <AddIcon />
            </IconButton>
            <IconButton aria-label="zoom-out" className="mapisso-zoom-button" onClick={onZoomOutClick}>
                <RemoveIcon />
            </IconButton>
        </div>
    )
}

export default MpsZoomBox;